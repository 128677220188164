<template>
    <div class="k-gold-trace">
        <div class="kg-title">
            K歌赢金币-平台配置
        </div>
        <div class="exchange" ref="barparent">
            <div class="title">
                金币获取途径
            </div>
            <el-table
                :data="topData"
                :header-cell-style="headCalss"
                max-height="300"
                border
                style="width: 100%">
                <el-table-column
                prop="ident"
                align="center"
                label="途径ID">
                </el-table-column>
                <el-table-column
                prop="desc"
                align="center"
                label="途径名称">
                </el-table-column>
                <el-table-column
                align="center"
                label="获得金币">
                    <template slot-scope="scope">
                        {{ scope.row.source == 1 ? scope.row.conis_rules : scope.row.coins_count }}
                    </template>
                </el-table-column>
                    
                  <el-table-column
                prop="address"
                align="center"
                label="小程序图标">
                    <template slot-scope="scope">
                        <img :src="scope.row.icon_url" alt="" style="height:35px">
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="exchange">
            <div class="title bnt">
                金币兑换礼物
            </div>
            <el-table
                :data="bntData"
                 max-height="300"
                border
                style="width: 100%">
                <el-table-column
                prop="title"
                label="商品名称"
                align="center">
                </el-table-column>
                <el-table-column
                prop="type"
                label="商品类型价值"
                align="center">
                </el-table-column>
                <el-table-column
                prop="ori_price"
                align="center"
                label="原价">
                </el-table-column>
                <el-table-column
                prop="price"
                align="center"
                label="销售价">
                </el-table-column>
                <el-table-column
                prop="coin_count"
                align="center"
                label="所需金币">
                </el-table-column>
                <el-table-column
                prop="storage_num"
                align="center"
                label="库存">
                </el-table-column>
                <el-table-column
                prop="notice"
                align="center"
                label="备注">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import { getGrantRule,getExchangeRule } from "../../api/index";
    export default {
         data() {
            return {
                tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
                }],
                heightTop:window.innerHeight - 500,
                heightBnt:window.innerHeight - 300,
                topData:[],
                bntData:[]
            }
        },
        created(){
           
            this._getGrantRule()
            this._getExchangeRule()
        },
        methods:{
            headCalss(){
                return 'background:#f5f7fa'
            },
            _getGrantRule(){
                getGrantRule({}).then(er =>{
                    console.log(er)
                    if(er.return_code == 200){
                        this.topData = er.data
                    }
                })
            },
            _getExchangeRule(){
                getExchangeRule().then(er =>{
                    console.log(er)
                    if(er.return_code == 200){
                        this.bntData = er.data
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .k-gold-trace{
        .kg-title{
            display: flex;
            align-items: center;
            height: 80px;
            font-size: 20px;
        }
        .title{
            height: 60px;
            display: flex;
            align-items: center;
            font-size: 16px;
        }
        .bnt{
            margin-top: 35px;
        }
        .exchange{
            max-height: calc(50% - 80px);
        }
    }
</style>